export enum Organisation {
  MIN_OF_FISH = 'Ministry of Fisheries',
  FIN_ECON_TRADE = 'Ministry of Finance, Economic Planning & Trade',
  FOREIGN_AFFAIRS = 'Ministry of Foreign Affairs and Tourism',
  INTERNAL_AFFAIRS = 'Ministry of Internal Affairs',
  TRANSPORT = 'Ministry of Transport',
  HEALTH = 'Ministry of Health',
  LANDS_HOUSING = 'Ministry of Lands and Housing',
  YOUTH_SPORTS_FAMILY = 'Ministry of Youth, Sports and Family',
  INVEST_ENTRE_IND = 'Ministry of Investment, Entrepreneurship and Industry',
  LOCAL_GOV_COM_AFFAIRS = 'Ministry of Local Government and Community Affairs',
  AGRI_CLIMATE_ENV = 'Ministry of Agriculture, Climate Change and Environment',
  EDUCATION = 'Ministry of Education',
  EMPLOY_SOCIAL_AFFAIRS = 'Ministry of Employment and Social Affairs',
}
