export enum CompanyRole {
  ACADEMICS = 'Academics',
  SERVICE_PROVIDER = 'ServiceProvider',
  MITIGATION_ACTIVITY_PARTNER = 'MitigationActivityParticipant',
  CARBON_CREDIT_BROKER = 'CarbonCreditBroker',
  VALIDATION_VERIFICATION_ENTITY = 'ValidationVerificationEntities',
  COMMERCIAL_BANKS = 'CommercialBanks',
  INVESTORS = 'InvestorsFinanciers',
  GOV_REGULATOR = 'GovernmentRegulators',
  OBSERVERS = 'Observers',
  CIVIL_SOCIETY_ORG = 'CivilSocietyOrganization',
  CERTIFIER = 'Certifier',
  INTERNAL_ORGANIZATION = 'InternationalOrganization',
  PROGRAMME_DEVELOPER = 'ProgrammeDeveloper',
  MRV = 'MRV',
  GOVERNMENT = 'Government',
  MINISTRY = 'Ministry',
  API = 'API',
}
